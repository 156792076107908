import React from "react";
import "./staking.css";
const Staking = () => {
  return (
    <div className="bgStaking-image">
      <div className="container ">
        <div className="row ">
          <span className="d-flex text-captilize text-heading">Staking</span>
        </div>
        <div className="row mt-5">
          <span className="d-flex text-para">
            Sacred Farms offer multiple farming opportunities to you. Get rewards by staking your USDT tokens in return for USDT and additional SFT tokens and earning high income.
          </span>
        </div>
      </div>
    </div>
  );
};
export default Staking;
