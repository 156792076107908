import React, { useEffect, useState } from "react";
import Footer from "../components/footer/footer";
import LevelDetail from "../components/level-detail/levelDetail";
import LevelCard from "../components/level-detail/LevelCard";
import { stakingAbi, stakingAddress } from "../utils/staking";
import { FiArrowRight } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
import { HashLink } from "react-router-hash-link";
import { IoMdArrowBack } from "react-icons/io";

function LevelPage(props) {
  const account = props?.account;
  const [levelNumber, setLevelNumber] = useState(1);
  const [levelAddressDetail, setlevelAddressDetails] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [totalRoi, setTotalRoi] = useState(0);
  const [userTotalDeposit, setUserTotalDeposit] = useState(0);
  const [userTotalClaim, setUserTotalClaim] = useState(0);
  const increment = () => {
    if (levelNumber < 10) setLevelNumber(levelNumber + 1);
  };
  const decrement = () => {
    if (levelNumber > 1) setLevelNumber(levelNumber - 1);
  };

  const handleRoiAndTotalUser = async () => {
    try {
      if (account == "No Wallet") {
        console.log("Not Connected");
      } else if (account == "Wrong Network") {
        console.log("Wrong Network");
      } else if (account == "Connect") {
        console.log("Not Connected");
      } else {
        const web3 = window.web3;
        const stakingContract = new web3.eth.Contract(
          stakingAbi,
          stakingAddress
        );
        const users = await stakingContract.methods
          .userCount(account, levelNumber)
          .call();
        setUserCount(users);
        if (users >= 1) {
          let roi = await stakingContract.methods
            .UpdateROIInfo1(account, levelNumber)
            .call();
          roi = web3.utils.fromWei(roi);
          roi = parseFloat(roi).toFixed(4);
          setTotalRoi(roi);
        } else {
          setTotalRoi(0);
        }

        await usersDetails(users);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const usersDetails = async (usersCounted) => {
    try {
      if (account == "No Wallet") {
        console.log("Not Connected");
      } else if (account == "Wrong Network") {
        console.log("Wrong Network");
      } else if (account == "Connect") {
        console.log("Not Connected");
      } else {
        const web3 = window.web3;
        const stakingContract = new web3.eth.Contract(
          stakingAbi,
          stakingAddress
        );

        let newArray = [];
        let depo;
        let res;
        let totalDepo;
        let reward;
        let affiliateGenerated;
        let userDeposit = 0;
        let userClaim = 0;
        if (usersCounted > 1) {
          for (let i = 1; i <= usersCounted; i++) {
            res = await stakingContract.methods
              .userReferral(account, levelNumber, i)
              .call();
            depo = await stakingContract.methods.totalDeposite(res).call();
            depo = web3.utils.fromWei(depo);
            totalDepo = await stakingContract.methods.userInfo(res).call();
            totalDepo = web3.utils.fromWei(totalDepo.totalDepositAmount);
            userDeposit = parseInt(userDeposit) + parseInt(totalDepo);
            setUserTotalDeposit(userDeposit);

            reward = await stakingContract.methods.rewardInfo(res).call();
            reward = web3.utils.fromWei(reward.total_Rewards);
            reward = parseFloat(reward).toFixed(4);
            userClaim = parseFloat(userClaim) + parseFloat(reward);
            userClaim = parseFloat(userClaim).toFixed(4);

            setUserTotalClaim(userClaim);

            affiliateGenerated = await stakingContract.methods
              .UpdateROIInfo(account, levelNumber, i)
              .call();

            affiliateGenerated = web3.utils.fromWei(affiliateGenerated);
            affiliateGenerated = parseFloat(affiliateGenerated).toFixed(4);
            newArray.push({
              index: i,
              address: res,
              currentDeposit: depo,
              totalDeposit: totalDepo,
              reward: reward,
              affiliateGenerated: affiliateGenerated,
            });
          }
        } else if (usersCounted == 1) {
          res = await stakingContract.methods
            .userReferral(account, levelNumber, 1)
            .call();
          depo = await stakingContract.methods.totalDeposite(res).call();
          depo = web3.utils.fromWei(depo);
          totalDepo = await stakingContract.methods.userInfo(res).call();
          totalDepo = web3.utils.fromWei(totalDepo.totalDepositAmount);
          userDeposit = parseInt(userDeposit) + parseInt(totalDepo);
          setUserTotalDeposit(userDeposit);

          reward = await stakingContract.methods.rewardInfo(res).call();
          reward = web3.utils.fromWei(reward.total_Rewards);
          reward = parseFloat(reward).toFixed(4);

          userClaim = parseFloat(userClaim) + parseFloat(reward);
          userClaim = parseFloat(userClaim).toFixed(4);
          setUserTotalClaim(userClaim);
          affiliateGenerated = await stakingContract.methods
            .UpdateROIInfo(account, levelNumber, 1)
            .call();

          affiliateGenerated = web3.utils.fromWei(affiliateGenerated);
          affiliateGenerated = parseFloat(affiliateGenerated).toFixed(4);
          newArray.push({
            index: 1,
            address: res,
            currentDeposit: depo,
            totalDeposit: totalDepo,
            reward: reward,
            affiliateGenerated: affiliateGenerated,
          });
        } else {
          affiliateGenerated = 0;

          newArray.push();
          setUserTotalDeposit(0);
          setUserTotalClaim(0);
        }
        setlevelAddressDetails([...newArray]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleRoiAndTotalUser();
  }, [account, levelNumber]);
  return (
    <div>
      <div>
        <LevelDetail />
      </div>
      <div style={{ background: "linear-gradient(311deg, #456732, #456732)" }}>
        <div className="container">
          <div className="row level-overflow">
            <div className="col-md-12 mt-5 ">
              <div className="row d-flex justify-content-center">
                <div className="col-md-12  mb-5">
                  <h3 className="text-level">level</h3>
                  <div className="button-left">
                    <HashLink className="arrow-color btn-arrow p-2" to="/">
                      <IoMdArrowBack />
                    </HashLink>
                  </div>
                  <div className="d-flex justify-content-center gap-2">
                    <button
                      className="btn-arrow"
                      onClick={() => {
                        decrement();
                      }}
                    >
                      <FiArrowLeft />
                    </button>
                    <span className="level-input">{levelNumber}</span>
                    <button
                      className="btn-arrow"
                      onClick={() => {
                        increment();
                      }}
                    >
                      <FiArrowRight />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container staked-container ">
            <div className="row d-flex justify-content-center boxLevel mb-5">
              <div className="col-sm-12 col-lg-3 staked-column">
                <span className="d-flex text-captilize staked-heading sub">
                  Total User
                </span>
                <span className="d-flex  staked-subheading">{userCount}</span>
              </div>
              <div className="col-sm-12 col-lg-3 staked-column">
                <span className="d-flex text-captilize staked-heading sub">
                  Total Deposit
                </span>
                <span className="d-flex  staked-subheading">
                  {userTotalDeposit} USDT
                </span>
              </div>
              <div className="col-sm-12 col-lg-3 staked-column">
                <span className="d-flex text-captilize staked-heading sub">
                  Total Claim
                </span>
                <span className="d-flex  staked-subheading">
                  {userTotalClaim} USDT
                </span>
              </div>
              <div className="col-sm-12 col-lg-3 staked-column">
                <span className="d-flex text-captilize staked-heading sub">
                  Total Affiliate Reward
                </span>
                <span className="d-flex  staked-subheading">
                  {totalRoi} USDT
                </span>
              </div>
            </div>
          </div>
          <div className="row level-overflow mt-5 mb-5">
            <div className="col-12 col-lg-12 col-sm-12 d-none d-xl-block">
              <LevelCard
                itemsPerPage={10}
                levelAddressDetail={levelAddressDetail}
                title={"Level Detail"}
              />
            </div>
            <div className="col-12 col-lg-12 col-sm-12 d-none d-xl-none d-lg-block">
              <LevelCard
                itemsPerPage={10}
                levelAddressDetail={levelAddressDetail}
                title={"Level Detail"}
              />
            </div>
            <div className="col-12 col-lg-12 col-sm-12 d-block d-lg-none d-xl-none">
              <LevelCard
                itemsPerPage={5}
                levelAddressDetail={levelAddressDetail}
                title={"Level Detail"}
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default LevelPage;
