import React from "react";
import "./levelDetail.css";

function levelDetail() {
  return (
    <div className="bglevel-image">
    <div className="container">
      <div className="row ">
        <span className="d-flex text-captilize text-heading">Level Detail</span>
      </div>
      <div className="row ">
        <span className="d-flex text-para">
        Sacred Farms offer multiple farming opportunities to you. Get rewards by staking your USDT tokens in return for USDT and additional SFT tokens and earning high income.
        </span>
      </div>
    </div>
    </div>
  );
}

export default levelDetail;
