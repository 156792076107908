import React from "react";
import "./stakedDetails.css";
const StakedDetails = () => {
  return (
    <div className="container-fluid   stakedDetail-container">
      <div className="row boxStakedDetail">
        <div className="col-lg-12 ">
          <p className=" text-captilize stakedDetail-heading mt-3">
            Total income: based on your tarrif plan (
            <b className="boldText">from 0.80% to 1.50% daily</b>)
          </p>
          <p className=" text-captilize stakedDetail-heading mt-3">
            Minimum deposit: <span className="boldText">10 USDT</span>, no
            maximum limit
          </p>
          <p className=" text-captilize stakedDetail-heading mt-3">
            Minimum withdraw: <span className="boldText">10 USDT</span>, no
            maximum limit
          </p>
          <p className=" text-captilize stakedDetail-heading mt-3">
            Earnings <span className="boldText">every moment</span> , withdraw
            <span className="boldText">any time</span> (if you use
            capitalization of interest you can withdraw only after end of your
            deposit)
          </p>
        </div>
      </div>
    </div>
  );
};
export default StakedDetails;
